/**
 * Global styles for Google Maps components.
 */

.gmap-popup-wrapper {
  position: absolute;
  width: 250px;
  height: 0;
  cursor: auto;
}

.gmap-popup-container {
  position: absolute;
  left: 8px;
  width: 100%;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-100%, -50%);
    border-width: 8px;
    border-style: solid;
    border-color: transparent white transparent transparent;
  }
}

.gmap-popup-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 240px;
  padding: 15px;
  overflow-y: auto;
  transform: translate(0, -50%);
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 0 16px 8px hsla(0, 0%, 0%, 0.1);
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
}

.gmap-popup-close {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 15px;
  height: 15px;
  cursor: pointer;
  &::before,
  &::after {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    height: 2px;
    width: 100%;
    background-color: black;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
}

.gmap-distance-label {
  filter: drop-shadow(0px 0px 5px #00000011);
  text-shadow: -1px -1px 0 #00000088, 1px -1px 0 #00000088, -1px 1px 0 #00000088, 1px 1px 0 #00000088;
}
